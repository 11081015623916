<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('paymentMethod') }}
  .row
    .col-8.col-lg-6.mt-1.position-static
      loading-logo(v-if="loading")
      payment-choose(
        :region="getRegion"
        :locale="$i18n.locale"
        :currentPaymentMethod="paymentMethod"
        :selectedPayment="selectedPayment"
        :price="0.01"
        deletablePayments
        v-if="!loading && !isShopifyPayment && hasPaymentMethod"
        @save="savePaymentMethod"
        ref="paymentChooser"
      )
      div(v-show="!loading && isShopifyPayment")
        .col-12 {{ $t('cannotChangePayment') }}
      div(v-show="!loading && !hasPaymentMethod")
        .col-12.mb-4.px-0 {{ $t('cannotChangePaymentNoMethod') }}
        om-button(
          primary
          :to="{ name: 'plan_settings', params: { userId: getAccountIdFromCookie() } }"
          v-if="!isShopifyPayment"
        ) {{ $t('changePlan') }}
      .hint(v-show="isShopifyPayment && isPageViewBasedPackage") {{ $t('cancelPlan.hints.forFreemium') }}
      .hint(v-show="isShopifyPayment && !isPageViewBasedPackage") {{ $t('cancelPlan.hints.forNotFreemiumShopify') }}
</template>

<script>
  import GET_BILLING from '@/graphql/GetBilling.gql';
  import CHANGE_PAYMENT from '@/graphql/ChangePayment.gql';
  import { getAccountIdFromCookie } from '@/util';
  import { get as _get } from 'lodash-es';
  import { mapGetters } from 'vuex';
  import PaymentChoose from '@/components/Payment/PaymentChoose';

  export default {
    components: { PaymentChoose },
    data() {
      return {
        SalesAdapter: null,
        paymentMethod: '',
        loading: true,
        selectedPayment: 'braintree',
        paymentError: null,
        redirectAfterAction: null,
      };
    },
    computed: {
      ...mapGetters(['getRegion', 'isPageViewBasedPackage']),
      hasPaymentMethod() {
        return !!this.paymentMethod;
      },
      isShopifyPayment() {
        return this.paymentMethod.indexOf('shopify') !== -1;
      },
      getSelectedPayment() {
        return this.selectedPayment;
      },
      getPeriod() {
        return this.$store.getters.selectedPeriod || this.$store.getters.currentPeriod;
      },
    },

    mounted() {
      if (this.$route.query.redirectAfterAction) {
        this.redirectAfterAction = this.$route.query.redirectAfterAction;
      }
      this.loadBilling();
    },
    methods: {
      getAccountIdFromCookie() {
        return getAccountIdFromCookie();
      },
      async savePaymentMethod(data) {
        const { method, payload } = data;
        this.loading = true;
        try {
          const { data } = await this.$apollo.mutate({
            mutation: CHANGE_PAYMENT,
            variables: {
              method,
              paymentNonce: payload.nonce,
            },
          });

          const success = _get(data, 'changePayment.success', false);
          if (success === true) {
            this.$notify({
              type: 'success',
              text: this.$t('notifications.saveSuccess'),
            });
            if (this.redirectAfterAction) {
              this.redirectByRouteName(this.redirectAfterAction);
              return;
            }
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.saveError'),
            });
            console.error(data);
          }
          this.loadBilling();
          this.$refs.paymentChooser.resetBraintree();
        } catch (err) {
          if (err.response && err.response.data && err.response.data.braintree) {
            this.$refs.paymentChooser.resetBraintree();
          }
        }
        this.loading = false;
      },

      async loadBilling() {
        this.loading = true;

        const { data } = await this.$apollo.query({
          query: GET_BILLING,
          variables: { planValidation: false },
        });

        const paymentMethod = _get(data, 'getBilling.paymentMethod.type', '');
        this.paymentMethod = paymentMethod;

        if (paymentMethod.indexOf('braintree') === 0) {
          this.selectedPayment = 'braintree';
        } else {
          this.selectedPayment = paymentMethod;
        }

        this.loading = false;
      },
      redirectByRouteName(routeName) {
        this.$router.replace({
          name: routeName,
        });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .brand-payment-card
    display: flex
    align-items: center
    border: 1px solid #E9EFF4
    border-radius: 3px
    margin-bottom: 20px
    min-height: 4.375rem
    flex-wrap: wrap
    &-braintree
      flex: 0 0 100%
</style>
